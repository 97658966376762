import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentStatus } from '@enum';
import { AuthService } from 'app/core/auth/auth.service';
import { OrderListItem } from 'app/core/interfaces/order-list-item.interface';
import { OrderService } from 'app/features/orders/services/order.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-amount-modal',
  templateUrl: './change-amount-modal.component.html',
  styleUrl: './change-amount-modal.component.scss',
})
export class ChangeAmountModalComponent {
  @Input() order!: OrderListItem;
  @Input() modalRef!: BsModalRef;

  @Output() updated = new EventEmitter<OrderListItem>();

  amountForm: FormGroup;
  submitted: boolean = false;
  confirm: boolean = false;

  validRegex: RegExp = /^\d*\.?\d{0,2}$/;

  constructor(
    private orderService: OrderService,
    private authService: AuthService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.amountForm = this.fb.group({
      amount: ['', [Validators.required, Validators.min(1)]],
    });
  }

  ngOnInit() {
    console.log('this.order', this.order);
    this.amountForm.setValue({
      amount: this.order.amount,
    });
  }

  get amountErrors() {
    const formValue = this.amountForm.get('amount');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    } else if (formValue?.hasError('min')) {
      return 'Debe ser un monto mayor a 0';
    }
    return '';
  }

  close = (): void => {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  };

  public onSubmit = (): void => {
    this.submitted = true;
    if (this.amountForm.valid) {
      this.order.estatus_payment = PaymentStatus.PENDING_PAYMENT;
      this.order.amount = this.amountForm.value.amount;
      this.orderService
        .updateOrder(this.order)
        .subscribe(({ data, code, errors }) => {
          if (code === 500) {
            this.submitted = false;
            if (errors) {
              return this.toastr.error(errors.number[0], '¡Error!');
            }
            return this.toastr.error(
              'No fue posible actualizar el monto de la orden',
              '¡Error!'
            );
          }
          this.updated.emit(data);
          this.toastr.success(`¡Monto actualizado!`, '¡Éxito!');
          this.close();
          return;
        });
    }
  };

  removeInitialSpaces() {
    const control = this.amountForm.get('number');
    const value = control?.value || '';
    control?.setValue(value.trimStart());
  }

  validateInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    if (value && !this.validRegex.test(value)) {
      input.value = value.slice(0, -1);
    }

    const parts = value.split('.');
    if (parts.length > 2) {
      input.value = parts[0] + '.' + parts.slice(1).join('');
    }
  }

  handlePaste(event: ClipboardEvent): void {
    const pastedData = event.clipboardData?.getData('text');
    if (
      pastedData &&
      (pastedData.includes('-') ||
        isNaN(Number(pastedData)) ||
        !this.validRegex.test(pastedData))
    ) {
      event.preventDefault();
    }
  }

  setConfirm() {
    this.confirm = true;
  }
}
