<div class="modal-header d-flex justify-content-center border-0">
  <h4 class="modal-title text-color fw-bold mt-3">Confirmar precio</h4>
</div>
<div class="modal-body text-center pt-0" *ngIf="!confirm">
  <form [formGroup]="amountForm" class="w-100 pt-2">
    <div
      class="container d-flex flex-column justify-content-center align-items-center"
    >
      <div class="form-group w-75 mt-3 me-1">
        <div class="form-floating">
          <input
            id="amount"
            type="text"
            class="form-control"
            placeholder="Precio"
            formControlName="amount"
            (input)="validateInput($event)"
            (paste)="handlePaste($event)"
          />
          <label for="amount">Precio</label>
        </div>
        <div class="text-danger pt-1">
          {{ amountErrors }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button
        class="btn btn-primary bg-ligth-mode ps-5 mt-5 pt-2 pb-2 pe-5"
        [disabled]="amountForm.invalid"
        (click)="setConfirm()"
      >
        Continuar
      </button>
    </div>
    <!-- <label class="clickable text-ligth-mode fw-bolder mt-3" (click)="close()">
      Cancelar
    </label> -->
  </form>
</div>
<div class="modal-body text-center pt-0" *ngIf="confirm">
  <h5 class="text-color-2">¿El monto ingresado es correcto?</h5>
  <h4 class="text-color-2">{{ "$" + amountForm.value.amount }}</h4>
  <div class="d-flex justify-content-center">
    <button
      class="btn btn-primary bg-ligth-mode ps-5 mt-5 pt-2 pb-2 pe-5"
      type="submit"
      [disabled]="submitted"
      (click)="onSubmit()"
    >
      Confirmar
    </button>
  </div>
  <label class="clickable text-ligth-mode fw-bolder mt-3" (click)="close()">
    Cancelar
  </label>
</div>
