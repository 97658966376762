import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

export const roleGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const expectedRoles = route.data['expectedRole'] as string[];

  if (authService.isFinance()) {
    router.navigate(['/orders']);
    return false;
  } else {
    if (authService.hasRoles(expectedRoles)) {
      return true;
    } else {
      return false;
    }
  }
};
