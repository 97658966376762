import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { ENDPOINT } from '@constants';
import { Login, User } from '@interfaces';
import { environment as env } from '@env/environment';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  errorService: any;
  private userSubject: BehaviorSubject<any>;
  user$: Observable<any>;

  constructor(private http: HttpClient, private datePipe: DatePipe) {
    const user = JSON.parse(sessionStorage.getItem('$session') || 'null');
    this.userSubject = new BehaviorSubject<any>(user);
    this.user$ = this.userSubject.asObservable();
  }

  login(loginData: Login): Observable<any> {
    return this.http.post<any>(ENDPOINT.AUTH.LOGIN, loginData).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  recoverPassword(number: string): Observable<any> {
    return this.http
      .post<any>(ENDPOINT.AUTH.SEND_RECOVER_EMAIL_BY_EMPLOYEE, {
        number,
        url: env.baseUrl,
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  validateToken(token: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http
      .post<any>(ENDPOINT.AUTH.VERIFY_RESET_TOKEN, {}, { headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  changePassword(password: string): Observable<any> {
    return this.http
      .post<any>(ENDPOINT.AUTH.CHANGE_FORGOTTEN_PASSWORD, { password })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  setSession(user: any): void {
    this.loadUserData(user);
    user.sessionStarted = this.formatDate(new Date());
    sessionStorage.setItem('$session', JSON.stringify(user));
    this.setToken(user.token);
  }

  removeSession(): void {
    sessionStorage.removeItem('$session');
    this.clearToken();
    this.userSubject.next(null);
  }

  getSession(): any | null {
    try {
      const sessionData = sessionStorage.getItem('$session');
      if (sessionData) {
        return JSON.parse(sessionData);
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error al obtener la sesión del sessionStorage:', error);
      return null;
    }
  }

  getUserId(): number {
    return this.getSession()?.id;
  }

  setToken(token: string): void {
    sessionStorage.setItem('$token', JSON.stringify(token));
  }

  getToken(): string | null {
    try {
      const token = sessionStorage.getItem('$token');
      if (token) {
        return JSON.parse(token);
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error al obtener el token del sessionStorage:', error);
      return null;
    }
  }

  clearToken(): void {
    sessionStorage.removeItem('$token');
  }

  private loadUserData(user: any): void {
    this.userSubject.next(user);
  }

  getUserData(): any {
    return this.userSubject.getValue();
  }

  formatDate = (date: Date | string | number | undefined): string => {
    return this.datePipe.transform(date, 'dd-MM-yyyy hh:mm:ss') ?? '';
  };

  hasRole(role: string): boolean {
    const user = this.getUserData();
    return user[role as keyof User] === 1;
  }

  hasRoles(roles: string[]): boolean {
    const user = this.getUserData();
    if (this.getUserData()) {
      return roles.some((role) => user[role as keyof User] === 1);
    }
    return false;
  }

  isAdmin(): boolean {
    return this.hasRole('admin_role');
  }

  isFinance(): boolean {
    return this.hasRole('finance_role');
  }

  isCallCenter(): boolean {
    return this.hasRole('call_center_role');
  }
}
