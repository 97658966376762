<div class="modal-header d-flex justify-content-center border-0">
  <h4 class="modal-title text-color fw-bold mt-3">Editar información</h4>
</div>
<div class="modal-body text-center pt-0">
  <p class="fw-bold text-color-2">Ingresa los datos correspondientes</p>
  <form [formGroup]="editUserForm" (ngSubmit)="onSubmit()" class="w-100 pt-2">
    <div
      class="container d-flex flex-column justify-content-center align-items-center"
    >
      <div class="form-group w-75 mb-3 disabled">
        <div class="form-floating">
          <input
            id="number"
            type="text"
            class="form-control"
            placeholder="Número de cliente"
            formControlName="number"
          />
          <label for="number">Número de cliente</label>
        </div>
        <div
          *ngIf="
            editUserForm.get('number')?.dirty ||
            editUserForm.get('number')?.touched
          "
        >
          <div class="text-danger ps-5 pt-1">
            {{ numberErrors }}
          </div>
        </div>
      </div>
      <div class="form-group w-75 mb-3">
        <div class="form-floating">
          <input
            id="name"
            type="text"
            class="form-control"
            placeholder="Nombre"
            formControlName="complete_name"
            (input)="onInput($event)"
            (paste)="onPaste($event)"
          />
          <label for="name">Nombre completo</label>
        </div>
        <div class="text-danger ps-5 pt-1">
          {{ completeNameErrors }}
        </div>
      </div>
      <div class="form-group w-75 mb-3">
        <div class="form-floating">
          <input
            id="email"
            type="email"
            class="form-control"
            placeholder="Correo electrónico"
            formControlName="email"
          />
          <label for="email">Correo electrónico</label>
        </div>
        <div class="text-danger ps-4 pt-1">
          {{ emailErrors }}
        </div>
      </div>
      <div class="form-group w-75 mb-3">
        <div class="form-floating">
          <input
            id="phone"
            type="text"
            class="form-control"
            placeholder="Teléfono"
            formControlName="phone"
          />
          <label for="phone">Teléfono</label>
        </div>
        <div class="text-danger ps-4 pt-1">
          {{ phoneErrors }}
        </div>
      </div>
      <div class="d-flex w-75 mb-3">
        <div class="form-group w-75 me-1">
          <div class="form-floating">
            <input
              id="municipality"
              type="text"
              class="form-control"
              placeholder="Municipio"
              formControlName="municipality"
            />
            <label for="municipality">Municipio</label>
          </div>
          <div class="text-danger pt-1">
            {{ municipalityErrors }}
          </div>
        </div>
        <div class="form-group w-75 ms-1">
          <div class="form-floating">
            <select id="state" class="form-select" formControlName="state">
              <option value="Michoacán">Michoacán</option>
              <option value="Guanajuato">Guanajuato</option>
            </select>
            <label for="state">Estado</label>
          </div>
          <div class="text-danger pt-1">
            {{ stateErrors }}
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button
        class="btn btn-primary bg-ligth-mode mt-5 pt-2 pb-2 ps-5 pe-5"
        type="submit"
        [disabled]="isDisabled()"
      >
        Guardar cambios
      </button>
    </div>
    <div class="d-flex justify-content-center"></div>
    <label class="clickable text-ligth-mode fw-bolder mt-3" (click)="close()">
      Cancelar
    </label>
  </form>
</div>
