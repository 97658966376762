import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { Address, FiscalData } from '@interfaces';
import { noSpacesEmailValidator, numebrsOnlyValidator } from '@validators';
import { taxSystemMoral } from 'app/core/constants/tax-system-moral';
import { taxSystemFisica } from 'app/core/constants/tax-system-fisica';
import { FileService } from 'app/core/services/file.service';
import { UserService } from 'app/features/users/services/user.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import * as pdfjsLib from 'pdfjs-dist';
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';
import { firstValueFrom } from 'rxjs';
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.mjs`;

@Component({
  selector: 'app-edit-client-billing-modal',
  templateUrl: './edit-client-billing-modal.component.html',
  styleUrl: './edit-client-billing-modal.component.scss',
})
export class EditClientBillingModalComponent {
  @Input() selectedBilling!: FiscalData;
  @Input() modalRef!: BsModalRef;

  fiscalData!: FiscalData;

  @Output() updated = new EventEmitter<FiscalData>();

  submitted: boolean = false;
  fiscalDataForm: FormGroup;
  isVisible: boolean = false;
  selectedTaxSystem: string[] = [];

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private fileService: FileService
  ) {
    this.fiscalDataForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(2),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(2),
          noSpacesEmailValidator(),
        ],
      ],
      postal_code: [
        '',
        [
          Validators.required,
          Validators.maxLength(5),
          Validators.minLength(5),
          numebrsOnlyValidator(),
        ],
      ],
      tax_system: ['', [Validators.required]],
      type: ['', [Validators.required]],
      RFC: [
        '',
        [
          Validators.required,
          Validators.maxLength(13),
          Validators.minLength(12),
        ],
      ],
    });
  }

  ngOnInit() {
    // this.loadPdf();
    this.selectedTaxSystem =
      this.selectedBilling.type == 'Moral' ? taxSystemMoral : taxSystemFisica;
    this.selectedBilling.tax_system = this.selectedBilling.tax_system.replace(
      /\n/g,
      ' '
    );
    this.fiscalDataForm.setValue({
      name: this.selectedBilling.name,
      email: this.selectedBilling.email,
      postal_code: this.selectedBilling.postal_code,
      tax_system: this.selectedBilling.tax_system,
      RFC: this.selectedBilling.RFC,
      type: this.selectedBilling.type,
    });
  }

  close = (): void => {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  };

  changeTaxSystem = (type: any): void => {
    if (type.target.value === 'Moral') {
      this.selectedTaxSystem = [...taxSystemMoral];
      this.fiscalDataForm.patchValue({
        tax_system: 'General de Ley Personas Morales',
      });
      return;
    }
    this.fiscalDataForm.patchValue({ tax_system: 'Sin obligaciones fiscales' });
    this.selectedTaxSystem = [...taxSystemFisica];
  };

  public onSubmit = (): void => {
    this.submitted = true;
    if (this.fiscalDataForm.valid) {
      this.fiscalDataForm.value.user_id = this.selectedBilling.user_id + '';
      this.fiscalDataForm.value.id = this.selectedBilling.id + '';
      this.fiscalDataForm.value.postal_code += '';
      this.userService
        .updateFiscalData(this.fiscalDataForm.value)
        .subscribe(({ data, code }) => {
          if (code === 500) {
            this.submitted = false;
            return this.toastr.error(
              'No fue posible actualizar la información de facturación',
              '¡Error!'
            );
          }
          this.updated.emit(data);
          this.toastr.success(
            `¡Información de facturación actualizada!`,
            '¡Éxito!'
          );
          this.close();
          return;
        });
    }
  };

  get nameErrors() {
    const formValue = this.fiscalDataForm.get('name');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    } else if (formValue?.hasError('maxlength')) {
      return 'Debe contener máximo 50 caracteres.';
    } else if (formValue?.hasError('length')) {
      return 'Debe contener al menos 2 caractere.';
    }
    return '';
  }

  get emailErrors() {
    const emailControl = this.fiscalDataForm.get('email');
    if (emailControl?.hasError('required')) {
      return 'Campo requerido';
    } else if (emailControl?.hasError('noSpacesEmail')) {
      return 'Formato inválido';
    } else if (emailControl?.hasError('maxlength')) {
      return 'Debe contener máximo 50 caracteres.';
    } else if (emailControl?.hasError('length')) {
      return 'Debe contener al menos 2 caracteres.';
    }
    return '';
  }

  get postalCodeErrors() {
    const formValue = this.fiscalDataForm.get('postal_code');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    } else if (formValue?.hasError('numbersOnly')) {
      return 'Solo se permiten números';
    } else if (
      formValue?.hasError('maxlength') ||
      formValue?.hasError('minlength')
    ) {
      return 'Debe contener exactamente 5 digitos';
    }
    return '';
  }

  get taxSystemErrors() {
    const formValue = this.fiscalDataForm.get('tax_system');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    }
    return '';
  }

  get typeErrors() {
    const formValue = this.fiscalDataForm.get('tax_system');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    }
    return '';
  }

  get rfcErrors() {
    const formValue = this.fiscalDataForm.get('RFC');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    } else if (formValue?.hasError('maxlength')) {
      return 'Debe contener máximo 13 caracteres.';
    } else if (formValue?.hasError('length')) {
      return 'Debe contener al menos 12 caractere.';
    }
    return '';
  }

  onInput(event: Event, formField: string): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const trimmedValue = value.trimStart();
    this.fiscalDataForm
      .get(formField)
      ?.setValue(trimmedValue, { emitEvent: false });
  }

  onPaste(event: ClipboardEvent, formField: string): void {
    const pastedData = event.clipboardData?.getData('text') || '';
    const trimmedData = pastedData.trimStart();
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const cursorPosition = input.selectionStart ?? 0;
    const currentValue = input.value;
    const newValue =
      currentValue.slice(0, cursorPosition) +
      trimmedData +
      currentValue.slice(input.selectionEnd ?? cursorPosition);
    input.value = newValue.trimStart();
    this.fiscalDataForm.get(formField)?.setValue(input.value);
  }

  noChangesFiscalDataInfo = (): boolean => {
    const {
      name: nameForm,
      email: emailForm,
      postal_code: postalCodeForm,
      tax_system: taxSystemForm,
      RFC: RFCForm,
    } = this.fiscalDataForm.value;

    const { name, email, postal_code, tax_system, RFC } = this.selectedBilling;
    return (
      name === nameForm &&
      email === emailForm &&
      postal_code === postalCodeForm &&
      tax_system === taxSystemForm &&
      RFC === RFCForm
    );
  };

  isDisabled = (): boolean => {
    return (
      this.noChangesFiscalDataInfo() ||
      this.fiscalDataForm.invalid ||
      this.submitted
    );
  };

  async loadPdf(): Promise<void> {
    try {
      const pdfBlob = await firstValueFrom(
        this.userService.getRecord(this.selectedBilling.record)
      );

      const pdfArrayBuffer = await pdfBlob!.arrayBuffer();

      const pdf = await pdfjsLib.getDocument({ data: pdfArrayBuffer }).promise;

      this.renderPage(pdf, 1);
    } catch (error) {
      console.error('Error al cargar el PDF:', error);
    }
  }

  renderPage(pdf: PDFDocumentProxy, pageNumber: number): void {
    pdf.getPage(pageNumber).then((page: PDFPageProxy) => {
      const viewport = page.getViewport({ scale: 0.2 });

      const canvas: HTMLCanvasElement = document.getElementById(
        'pdf-canvas'
      ) as HTMLCanvasElement;
      const context = canvas.getContext('2d');

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      if (context) {
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        page.render(renderContext);
      }
    });
  }

  downloadPDF() {
    const url = `${environment.storageRecordsUrl}${this.selectedBilling.record}`;
    this.fileService.downloadFile(url, this.selectedBilling.record);
  }
}
