import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderStatus, PaymentStatus } from '@enum';
import { noSpacesValidator, noSpecialCharacters } from '@validators';
import { AuthService } from 'app/core/auth/auth.service';
import { OrderListItem } from 'app/core/interfaces/order-list-item.interface';
import { OrderService } from 'app/features/orders/services/order.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-set-folio-modal',
  templateUrl: './set-folio-modal.component.html',
  styleUrl: './set-folio-modal.component.scss',
})
export class SetFolioModalComponent {
  @Input() order!: OrderListItem;
  @Input() modalRef!: BsModalRef;

  @Output() updated = new EventEmitter<OrderListItem>();

  folioForm: FormGroup;
  submitted: boolean = false;
  confirm: boolean = false;

  validRegex: RegExp = /^\d*\.?\d{0,2}$/;

  constructor(
    private orderService: OrderService,
    private authService: AuthService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.folioForm = this.fb.group({
      number: [
        '',
        [Validators.required, noSpacesValidator(), noSpecialCharacters()],
      ],
    });
  }

  ngOnInit() {
    this.folioForm.setValue({
      number: this.order.number,
    });
  }

  get folioErrors() {
    const formValue = this.folioForm.get('number');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    }
    if (formValue?.hasError('noSpecialCharacters')) {
      return 'Solo se permiten letras y números';
    }
    if (formValue?.hasError('noSpaces')) {
      return 'No puede contener espacios';
    }
    return '';
  }

  close = (): void => {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  };

  public onSubmit = (): void => {
    this.submitted = true;
    if (this.folioForm.valid) {
      if (this.order.number === this.folioForm.value.number) {
        this.updated.emit();
        this.close();
        return;
      }
      if (this.order.number) {
        this.order.number = this.folioForm.value.number;
        this.orderService
          .updateFolio(this.order.id, this.order.number)
          .subscribe(({ data, code, errors }) => {
            if (code === 500) {
              this.submitted = false;
              if (errors) {
                return this.toastr.error(errors.number[0], '¡Error!');
              }
              return this.toastr.error(
                'No fue posible actualizar el folio de la orden',
                '¡Error!'
              );
            }
            this.updated.emit(data);
            this.toastr.success(`¡Folio actualizado!`, '¡Éxito!');
            this.close();
            return;
          });
      }
      this.order.number = this.folioForm.value.number;
      this.order.user_add = this.authService.getUserId();
      this.order.estatus = OrderStatus.IN_PROGRESS;
      this.orderService
        .updateOrder(this.order)
        .subscribe(({ data, code, errors }) => {
          if (code === 500) {
            this.submitted = false;
            if (errors) {
              return this.toastr.error(errors.number[0], '¡Error!');
            }
            return this.toastr.error(
              'No fue posible actualizar el folio de la orden',
              '¡Error!'
            );
          }
          this.updated.emit(data);
          this.toastr.success(`¡Folio asignado!`, '¡Éxito!');
          this.close();
          return;
        });
    }
  };

  removeInitialSpaces() {
    const control = this.folioForm.get('number');
    const value = control?.value || '';
    control?.setValue(value.trimStart());
  }

  setConfirm() {
    this.confirm = true;
  }
}
